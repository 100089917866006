<template>
  <div>
    <el-dialog v-model="dialog_visible" modal-class="monitor-dialog-modal" custom-class="monitor-dialog"
      :close-on-click-modal="false" :close-on-press-escape="false" align-center :show-close="false"
      :style="'background-image: url(' + monitor_dialog_bg + ');background-size: cover'">
      <template #header>
        <div class="monitor-dialog-header">
          <div class="monitor-dialog-title">分类统计</div>
          <div class="monitor-dialog-close" @click="close"></div>
        </div>
      </template>
      <div class="monitor-dialog-content classification-statistics" v-if="allEventDetail">
        <div class="classification-statistics-left">
          <div class="classification-statistics-left-top flex flex-direction">
            <div class="classification-title-row flex">
              <div class="prefix"></div>
              <div class="title-text">预警总数</div>
            </div>
            <div class="classification-content-row flex-1 flex justify-center align-center">
              <div class="water-polo flex justify-center align-center">
                <div class="water-polo-inner flex justify-center align-center">
                  <div class="water-polo-inner-container">
                    <div class="value-content">
                      <div>{{ numerization(allEventDetail.size) }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="classification-statistics-left-bottom flex flex-direction">
            <div class="classification-title-row flex">
              <div class="prefix"></div>
              <div class="title-text">预警楼栋分布</div>
            </div>
            <div class="classification-content-row flex-1 flex justify-center align-center" ref="charts">

            </div>
          </div>
        </div>
        <div class="classification-statistics-right">
          <div class="classification-title-row flex">
            <div class="prefix"></div>
            <div class="title-text">预警类型统计</div>
          </div>
          <div class="classification-statistics-right-content">
            <div v-for="iter in allEventDetail.warningEventTypeCountList" :key="iter" class="event-item">
              <div class="flex justify-between align-center">
                <div class="fontSize14">{{ iter.name }}</div>
                <div style="color: #F5E74F;">
                  <span>{{ iter.size }}</span>
                  <span class="marginLeft5">({{ (iter.ratio * 100).toFixed(2) }}%)</span>
                </div>
              </div>
              <div class="marginTop10px">
                <el-progress :show-text="false" :stroke-width="16" :percentage="iter.ratio * 100" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { numerization } from "@/utils/common";
import { warningBuildingEcharts } from "@/view/visualization/echarts/warningBuildingEcharts"
import { queryIndexAllDataView } from "@/api/monitor"
export default {
  name: 'classificationStatistics',
  props: {
    dialog_visible: {
      type: Boolean,
      default() {
        return false
      }
    },
    times: {
      type: Array,
      default(){
        return []
      }
    }
  },
  data() {
    return {
      monitor_dialog_bg: require('@/static/img/monitor/monitor-dialog-bg.png'),
      allEventDetail: null,
      buildingData: []
    }
  },
  mounted() {
    this.getAllEventDetail()
  },
  methods: {
    numerization,
    /**
     * 绘制预警总数
     */
    drawWarnings() {
      this.$nextTick(() => {
        const charts = this.$refs["charts"]
        const myChart = echarts.init(charts)
        myChart.setOption(warningBuildingEcharts(this.buildingData))
        myChart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: 1,
        })
        myChart.on('mouseover', function (e) {
          myChart.dispatchAction({ type: 'downplay', seriesIndex: 0, dataIndex: 1 });
        });
        myChart.on('mouseout', function (e) {
          myChart.dispatchAction({ type: 'highlight', seriesIndex: 0, dataIndex: 1 });
        });
      })

    },
    // 获取所有事件详情
    async getAllEventDetail() {
      const params = {}
      if (Array.isArray(this.times) && this.times.length === 2) {
        params.startTime = this.times[0]
        params.endTime = this.times[1]
      }
      let res = await queryIndexAllDataView(params)
      if (res.code == 200) {
        this.allEventDetail = res.data
        let color = ["#009DFF", "#59FFFF", "#F5E74F", "#FF386B", "#fff"]
        res.data.buildingData.forEach((item, index) => {
          this.buildingData.push(
            {
              value: item.size,
              name: item.buildIngName + "  " + (item.ratio * 100).toFixed(2) + '%',
              itemStyle: {
                color: color[index]
              }
            }
            )
          })
          this.drawWarnings()
        console.log(this.buildingData);
      }
      console.log(res);
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.monitor-dialog {
  --el-dialog-width: 1420px;
  height: 800px;
  --el-dialog-bg-color: transparent;
  --el-box-shadow: none;

  .el-dialog__body {
    padding: 0 !important;
  }
}
</style>

<style scoped lang="scss">
.event-item {
  /deep/ .el-progress-bar__outer {
    background: #191922;
  }

  /deep/ .el-progress-bar__inner {
    background: linear-gradient(90deg, #59FFFF 0%, #009DFF 100%);
  }
}

.monitor-dialog-header {
  position: relative;
  width: 100%;
  height: 64px;

  .monitor-dialog-title {
    position: absolute;
    top: 9px;
    left: 20px;
    font-size: 24px;
    font-family: YouSheBiaoTiHei;
    font-weight: 400;
    color: #009DFF;
    line-height: 32px;
    letter-spacing: 1px;
  }

  .monitor-dialog-close {
    position: absolute;
    right: -10px;
    top: 25px;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
}

.monitor-dialog-content {
  width: 1400px;
  height: 736px;
  padding: 0 20px 20px 20px;
  box-sizing: border-box;
  display: flex;
}

.classification-statistics {
  .classification-title-row {
    align-items: center;
    .prefix {
      margin-right: 8px;
      width: 4px;
      height: 20px;
      background: #009DFF;
      border-radius: 0 4px 0 0;
    }

    .title-text {
      font-size: 18px;
      color: #009DFF;
    }
  }

  .classification-content-row {
    .water-polo {
      width: 224px;
      height: 224px;
      border-radius: 50%;
      background: linear-gradient(90deg, rgba(89, 255, 255, 1), rgba(0, 157, 255, 1));

      .water-polo-inner {
        width: 220px;
        height: 220px;
        border-radius: 50%;
        background-color: #00000a;

        .water-polo-inner-container {
          position: relative;
          width: 200px;
          height: 200px;
          border-radius: 50%;
          background: linear-gradient(90deg, #59FFFF 0%, #009DFF 100%);
          overflow: hidden;

          @keyframes rotate {
            50% {
              transform: translate(-50%, -73%) rotate(180deg);
            }

            100% {
              transform: translate(-50%, -70%) rotate(360deg);
            }
          }

          .value-content {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            z-index: 30;

            font-size: 32px;
            color: #FFFFFF;
            letter-spacing: 1px;
            text-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);

            div {
              font-family: YouSheBiaoTiHei;
            }
          }

          // bai
          &::before,
          &::after {
            content: "";
            position: absolute;
            width: 400px;
            height: 400px;
            top: 0;
            left: 50%;
            background-color: rgba(255, 255, 255, .4);
            border-radius: 40%;
            transform: translate(-50%, -70%) rotate(0);
            animation: rotate 6s linear infinite;
            z-index: 10;
          }

          // hei
          &::after {
            border-radius: 47%;
            background-color: #00000a;
            transform: translate(-50%, -70%) rotate(0);
            animation: rotate 10s linear -5s infinite;
            z-index: 20;
          }
        }
      }
    }
  }

  &-left {
    width: 50%;

    &-top {
      width: 100%;
      height: 50%;
    }

    &-bottom {
      width: 100%;
      height: 50%;
    }
  }

  &-right {
    width: 50%;

    &-content {
      padding-top: 32px;
      padding-left: 56px;
      padding-right: 36px;
      overflow-y: auto;
      height: 655px;

      &::-webkit-scrollbar {
        display: none
      }

      .event-item {
        margin: 0 auto 22px;
        width: 390px;
        height: 50px;
        flex-shrink: 0;
        color: white;
      }
    }
  }
}
</style>
