<template>
  <div class="video-monitor warning-list" :style="'background-image: url(' + contentBg + ');background-size: cover'">
    <div class="video-monitor-title flex">
      <span class="flex" style="cursor: pointer;" @click="$router.go(-1)">
        <img src="@/static/img/monitor/back.png" alt="">
        <span class="marginLeft12">预警档案</span>
      </span>
    </div>
    <div style="width: 100%;padding:20px;box-sizing: border-box;">
      <div style="width: 100%;" class="project-search flex container">
        <div>
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item class="status" label="状态：">
              <el-select v-model="pageInfo.state" :teleported="false" placeholder="请选择状态" size="large">
                <el-option label="全部" value="" />
                <el-option v-for="(value, key, index) in stateOptions" :key="index" :label="value"
                  :value="key"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="type" label="事件类型：">
              <el-select v-model="pageInfo.warningEventTypeId" :teleported="false" placeholder="请选择事件类型" size="large">
                <el-option v-for="(item, index) in eventTypeOption" :key="index" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
            <el-form-item label="解除方式：">
              <el-select v-model="pageInfo.secureType" :teleported="false" placeholder="请选择解除方式" size="large">
                <el-option label="全部" value="" />
                <el-option v-for="(value, key, index) in secureTypeOptions" :key="index" :label="value"
                  :value="key"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="building" label="楼栋：">
              <el-select v-model="pageInfo.buildingId" :teleported="false" placeholder="请选择楼栋" size="large">
                <el-option v-for="(item, index) in allBuildingOption" :key="index" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
            <el-form-item label="预警时间：">
              <div class="block">
                <!-- <el-date-picker v-model="pageInfo.time" :teleported="false" type="datetime"
                  value-format="YYYY-MM-DD HH:mm:ss" popper-class="custom-date-picker" placeholder="请选择日期时间"
                  size="large" /> -->
                <el-date-picker clearable v-model="_times" type="datetimerange" value-format="YYYY-MM-DD HH:mm:ss"
                  range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="请选择预警时间范围"
                  popper-class="custom-date-picker" size="large" />
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="container-right flex justify-between">
          <div style="width: 150px;">
            <el-input v-model="pageInfo.search" placeholder="输入关键字查询" size="large" />
          </div>
          <div class="flex">
            <button class="searchBtn" style="cursor: pointer;" @click="search">搜索</button>
            <button class="resetBtn" style="cursor: pointer;" @click="reset">重置</button>
            <div class="ranking-outer marginLeft12">
              <div class="ranking-container flex align-center justify-center" style="" @click="showStatistics">
                <img src="@/static/img/monitor/ranking.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 100%;" class="project-table">
        <el-table :data="tableData" row-key="id"
          :header-cell-style="{ 'text-align': 'center', 'color': '#80CEFF', 'padding': '16px 0', 'background-color': '#EBEBEB00' }"
          :cell-style="{ 'text-align': 'center' }" :row-style="{
            color: '#fff',
          }" style="--el-table-border-color: none;" :height="680">
          <el-table-column label="序号" type="index" width="60" />
          <el-table-column prop="warningEventType.name" label="事件类型" />
          <el-table-column prop="building.name" label="楼栋" />
          <el-table-column prop="laboratory.name" label="地点" />
          <el-table-column prop="time" label="预警时间" width="170" />
          <el-table-column prop="state" label="状态">
            <template #default="{ row }">
              <span :style="{ 'color': row.state ? '#59FFFF' : '#FF386B' }">{{ stateOptions[String(row.state)] }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="secureType" label="解除方式">
            <template #default="{ row }">
              {{ secureTypeOptions[String(row.secureType)] }}
            </template>
          </el-table-column>
          <el-table-column prop="secureTime" label="解除时间" width="170" />
          <el-table-column label="操作" align="center">
            <template #default="{ row }">
              <span style="background:transparent; color:#3769B9;cursor: pointer;" @click="eventDetail(row)">详情</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-bar flex justify-center">
          <!--   分页     -->
          <el-pagination :currentPage="pageInfo.page" :page-size="pageInfo.limit" :small="true" :disabled="false"
            :background="true" layout="total, prev, pager, next, jumper" :total="pageInfo.total"
            @current-change="handleCurrentChange" />
        </div>
      </div>
    </div>
    <monitor-detail :eventRow="eventRow" v-if="monitor_detail_visible" @close="monitorDetailClose"></monitor-detail>
  </div>
  <!-- 分类统计弹层 -->
  <classification-statistics v-if="statisticsPopupShow"
                             :dialog_visible="statisticsPopupShow"
                             :times="_times"
                             ref="classificationStatistics"
                             @close="statisticsPopupShow = false" />
</template>

<script>
import ClassificationStatistics from "@/view/visualization/components/classificationStatistics";
import monitorDetail from "@/view/visualization/components/monitorDetail.vue";
import { allWarningEventType, warningEventPage, getAllBuilding } from "@/api/monitor"
import { WARNING_EVENT_STATE, WARNING_EVENT_SECURE_TYPE } from "@/utils/config"
export default {
  name: 'warningList',
  components: { ClassificationStatistics, monitorDetail },
  data() {
    return {
      stateOptions: WARNING_EVENT_STATE,
      secureTypeOptions: WARNING_EVENT_SECURE_TYPE,
      eventTypeOption: [],
      allBuildingOption: [],
      // require
      contentBg: require('@/static/img/monitor/vodeoContainerBg.png'),
      tableData: [],
      pageInfo: {
        limit: 15,
        page: 1,
        total: 0,
        state: '0',
        warningEventTypeId: null,
        secureType: null,
        buildingId: null,
        search: null,
        startTime: null,
        endTime: null
      },
      monitor_detail_visible: false,
      eventRow: null,
      // - i
      statisticsPopupShow: false,
      _times: []
    }
  },
  created() {
    this.getAllEventType()
    this.getAllEventList()
    this.getAllBuilding()
  },
  methods: {
    // -e
    showStatistics() {
      // 显示
      this.statisticsPopupShow = true
      // 绘制
      // this.$nextTick(() => {
      //   this.$refs.classificationStatistics.drawWarnings()

      // })
    },
    // 获取楼栋
    async getAllBuilding() {
      let { data } = await getAllBuilding()
      this.allBuildingOption = data.map(item => {
        return {
          name: item.name,
          id: item.id
        }
      })
      console.log(data);
    },
    // 获取事件类型
    async getAllEventType() {
      let { data } = await allWarningEventType()
      this.eventTypeOption = data.map(item => {
        return {
          name: item.name,
          id: item.id
        }
      })
      console.log(this.eventTypeOption);
    },
    // 获取所有事件
    async getAllEventList() {
      let { data } = await warningEventPage(this.pageInfo)
      this.pageInfo.total = data.total
      this.tableData = data.records
      console.log(data);
    },
    monitorDetailClose() {
      this.monitor_detail_visible = false
      this.getAllEventList()
    },
    // 详情
    eventDetail(row) {
      this.eventRow = row
      this.monitor_detail_visible = true
    },
    // 搜索
    search() {
      this.pageInfo.startTime = this._times[0]
      this.pageInfo.endTime = this._times[1]
      this.pageInfo.page = 1
      this.pageInfo.limit = 15
      this.getAllEventList()
    },
    // 重置
    reset() {
      this._times = []
      Object.assign(this.pageInfo, {
        state: null,
        warningEventTypeId: null,
        secureType: null,
        buildingId: null,
        startTime: null,
        endTime: null,
        search: null
      })
    },
    // 分页
    handleCurrentChange(e) {
      this.pageInfo.page = e;
      this.getAllEventList();
    },
  }
}
</script>

<style lang="scss" scoped>
.warning-list {
  /deep/ .el-form-item__label {
    color: #B4C0CC;
  }

  // 表格
  //最外层透明
  /deep/ .el-table,
  .el-table__expanded-cell {
    background-color: transparent;
  }

  //表格内背景颜色
  /deep/ .el-table th,
  /deep/ .el-table tr,
  /deep/ .el-table td {
    background-color: transparent;
  }

  //悬停
  /deep/ .el-table {
    --el-table-row-hover-bg-color: rgba(0, 158, 250, 0.219);
    --el-table-row-hover-background-color: rgba(0, 158, 250, 0.219) !important;
  }

  /deep/ .el-table .el-table__row:hover {
    .el-table_1_column_1 {
      border-left: 2px solid #009DFF !important;
    }
  }

  //选中
  /deep/ .el-table {
    --el-table-row-hover-bg-color: rgba(0, 158, 250, 0.219);
    --el-table-current-row-background-color: rgba(0, 158, 250, 0.219) !important;
  }

  // 分页
  /deep/ .el-pager {
    --el-pagination-button-bg-color: rgba(0, 157, 255, 0.1);
  }

  /deep/ .el-pagination.is-background .btn-next {
    background: rgba(0, 157, 255, 0.1);
    box-shadow: inset 0 0 4px 0 #009DFF;
    border-radius: 2px;
    border: 1px solid #009DFF;
  }

  /deep/ .el-pagination.is-background .btn-prev {
    background: rgba(0, 157, 255, 0.1);
    box-shadow: inset 0 0 4px 0 #009DFF;
    border-radius: 2px;
    border: 1px solid #009DFF;
  }

  /deep/ .el-pagination.is-background .el-pager li {
    background: rgba(0, 157, 255, 0.1);
    box-shadow: inset 0 0 4px 0 #009DFF;
    border-radius: 2px;
    border: 1px solid #009DFF;
  }

  /deep/ .el-pagination__total {
    color: #009DFF;
    font-size: 14px;
  }

  /deep/ .el-pager li:not(.is-disabled).is-active {
    background-color: #009DFF !important;
    color: #ffffff !important;
  }

  /deep/ .el-pagination__jump {
    color: #ffffff;
    font-size: 14px;
  }

  .project-search {
    background-color: #00000000;
    padding: 24px 0 8px;
    font-size: 14px;
    color: #333333;
  }

  .project-table {
    padding: 0;
    border-radius: 0;

    //
    background: rgb(0, 67, 107);
    background: linear-gradient(180deg, rgba(0, 67, 107, 1) 0%, rgba(0, 0, 10, 1) 15%);

    .pagination-bar{
      padding-top: 60px;
    }
  }

  // ----------
  .demo-form-inline,
  .container-right,
  .project-table {
    --el-text-color-regular: #B4C0CC;
    --el-fill-color-blank: rgba(0, 0, 0, 0);
    --el-border-color: #009DFF;
    --el-border-color-hover: #009DFF;
    --el-select-input-focus-border-color: #009DFF;
    --el-color-primary: #009DFF;
    --el-select-border-color-hover: #009DFF;
    --el-input-focus-border-color: #009DFF;
    --el-input-hover-border-color: #009DFF;
    --el-text-color-disabled: #009DFF;
    // 下拉框背景
    --el-bg-color-overlay: #00000a;
    --el-fill-color-light: #001e38;

    --el-color-white: #ffffff;
    --el-color-black: #000000;
    --el-color-primary-light-3: #79bbff;
    --el-color-primary-light-5: #a0cfff;
    --el-color-primary-light-7: #c6e2ff;
    --el-color-primary-light-8: #d9ecff;
    --el-color-primary-light-9: #ecf5ff;
    --el-color-primary-dark-2: #337ecc;

    --el-bg-color: #ffffff;
    --el-bg-color-page: #f2f3f5;
    --el-text-color-primary: #303133;
    --el-text-color-secondary: #909399;
    --el-text-color-placeholder: #a8abb2;
    --el-border-color-light: #009DFF;
    --el-border-color-lighter: #009DFF;
    --el-border-color-extra-light: #009DFF;
    --el-border-color-dark: #009DFF;
    --el-border-color-darker: #009DFF;
    --el-fill-color: #009DFF;
    //--el-fill-color-lighter: #009DFF;
    --el-fill-color-extra-light: #009DFF;
    --el-fill-color-dark: #009DFF;
    --el-fill-color-darker: #009DFF;
    --el-box-shadow: 0px 12px 32px 4px rgba(0, 0, 0, 0.04), 0px 8px 20px rgba(0, 0, 0, 0.08);
    --el-box-shadow-light: 0px 0px 12px rgba(0, 0, 0, 0.12);
    --el-box-shadow-lighter: 0px 0px 6px rgba(0, 0, 0, 0.12);
    --el-box-shadow-dark: 0px 16px 48px 16px rgba(0, 0, 0, 0.08), 0px 12px 32px rgba(0, 0, 0, 0.12), 0px 8px 16px -8px rgba(0, 0, 0, 0.16);
    --el-disabled-bg-color: var(--el-fill-color-light);
    --el-disabled-text-color: var(--el-text-color-placeholder);
    --el-disabled-border-color: var(--el-border-color-light);
    --el-overlay-color: rgba(0, 0, 0, 0.8);
    --el-overlay-color-light: rgba(0, 0, 0, 0.7);
    --el-overlay-color-lighter: rgba(0, 0, 0, 0.5);
    --el-mask-color: rgba(255, 255, 255, 0.9);
    --el-mask-color-extra-light: rgba(255, 255, 255, 0.3);
    --el-border-width: 1px;
    --el-border-style: solid;
    --el-border: var(--el-border-width) var(--el-border-style) var(--el-border-color);
    --el-svg-monochrome-grey: var(--el-border-color);

    .searchBtn {
      background-color: #009DFF;
    }

    .resetBtn {
      background: rgba(0, 157, 255, 0.1);
      box-shadow: inset 0 0 4px 0 #009DFF;
      border-radius: 5px;
      border: 1px solid #009DFF;
    }

    .ranking-outer {
      height: 100%;
      cursor: pointer;

      .ranking-container {
        height: 40px;
        width: 40px;
        background: rgba(0, 157, 255, 0.1);
        box-shadow: inset 0 0 4px 0 #009DFF;
        border-radius: 5px;
        border: 1px solid #009DFF;

        img {
          width: 24px;
          height: 24px;
          object-fit: cover;
        }
      }
    }
  }
}

.video-monitor {
  position: absolute;
  top: 112px;
  left: 24px;

  width: 1872px;
  height: 944px;

  .video-monitor-title {
    position: relative;
    left: 20px;
    top: 9px;

    span {
      font-size: 24px;
      font-family: YouSheBiaoTiHei;
      color: #009DFF;
    }
  }
}

.container {
  /deep/ .el-form {
    display: flex;
  }

  ::v-deep .el-form--inline .el-form-item {
    width: 16%;
  }

  ::v-deep .el-range-editor--large.el-input__wrapper {
    width: 335px;
  }

  &-right {
    flex-shrink: 0;
  }

  .building {
    width: 160px !important;

    ::v-deep .el-form-item__content {
      width: 130px !important;
    }

    ::v-deep .el-select .el-input__wrapper {
      width: 90px;
    }
  }

  .status {
    width: 170px !important;

    ::v-deep .el-form-item__content {
      width: 100px !important;
    }

    ::v-deep .el-select .el-input__wrapper {
      width: 100px !important;
    }
  }

  .type {
    width: 230px !important;

    ::v-deep .el-form-item__content {
      width: 130px !important;
    }

    ::v-deep .el-select .el-input__wrapper {
      width: 130px !important;
    }
  }
}</style>